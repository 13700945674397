import { createSlice } from "@reduxjs/toolkit";
const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: {},
        err: "",
        accessToken: "",
        refreshToken: "",
        isLoggedIn: false,
        loading: "idle", //loading | idle | error
    },
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setErr(state, action) {
            state.err = action.payload;
        },
        setAccessToken(state, action) {
            state.accessToken = action.payload;
        },
        setRefreshToken(state, action) {
            state.refreshToken = action.payload;
        },
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        signOut(state, action) {
            state.user = {};
            state.accessToken = "";
            state.isLoggedIn = false;
        }
    },
});

export const authActions = authSlice.actions;
export default authSlice;
