import { createSlice } from "@reduxjs/toolkit";
const categorySlice = createSlice({
    name: "category",
    initialState: {
        allCategories: [],
        allProductCategories: [],
        allToppingCategories: [],
    },
    reducers: {
        setAllCategories(state, action) {
            state.allCategories = action.payload;
        },
        setAllProductCategories(state, action) {
            state.allProductCategories = action.payload;
        },
        setAllToppingCategories(state, action) {
            state.allToppingCategories = action.payload;
        },
    },
});

export const categoryActions = categorySlice.actions;
export default categorySlice;
