import { createSlice } from "@reduxjs/toolkit";
const vendorSlice = createSlice({
    name: "vendor",
    initialState: {
        vendor: {},
    },
    reducers: {
        setVendor(state, action) {
            state.vendor = action.payload;
        },
    },
});


export const vendorActions = vendorSlice.actions;
export default vendorSlice;
