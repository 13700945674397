import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

const initialState=[]
const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addItem(state, action) {
            return [action.payload]; // Replace the current cart array with a new array containing the new item
         },
          incrementQuantity(state,action) {
            const { total_price } = action.payload;
            state[0].total_price = total_price;
            state[0].quantity += 1; // Increment the quantity of the item in the cart
            state[0].totalWithInsurance = (state[0].project_persharecost + Number(state[0].insuranceAmount)) * state[0].quantity;
          },
          decrementQuantity(state,action) {
            const { total_price} = action.payload;
            if (state[0].quantity > 1) {
                state[0].total_price = total_price;
                state[0].quantity -= 1; // Decrement the quantity of the item in the cart, ensuring it doesn't go below 1
                state[0].totalWithInsurance = (state[0].project_persharecost + Number(state[0].insuranceAmount)) * state[0].quantity;
            }
          },
          updateInsurance(state, action) {
            const { isInsurance } = action.payload;
            state[0].isInsurance = isInsurance; // Update the isInsurance value of the item in the cart
          },
         
        remove (state,action) {
            state=state.filter(item=>item.id !==action.payload)
        },
        setCart(state, action) {
            state.cart = JSON.parse(action.payload);
        },
        clearCart(state) {
            return []; // Clear the cart by returning an empty array
          },
        addToCart(state, action) {
            const newItem = action.payload;

            const existingItem = state.cart.find(c => c == newItem);
            if (existingItem) {
                existingItem.quantity++;
            } else {
                if (state.cart.length > 0) {
                    let data = {
                        id: state.cart[state.cart.length - 1].id + 1,
                        ...newItem
                    }
                    state.cart.push(data);
                } else {
                    let data = {
                        id: 1,
                        ...newItem
                    }
                    state.cart.push(data);
                }

            }
            if (typeof window !== 'undefined') {
                localStorage.setItem("cartData", JSON.stringify(state.cart));
            }
            toast.success(`${newItem.product_name} - Added to Cart!`);
        },
        removeFromCart(state, action) {
            const item = action.payload;
            state.cart = state.cart.filter(c => c.id !== item.id);
            if (typeof window !== 'undefined') {
                localStorage.setItem("cartData", JSON.stringify(state.cart));
            }
            toast.error(`${item.product_name} - Removed From Cart`);
        },
        incQuantity(state, action) {
            const item = action.payload;
            let existingItem = state.cart.find((c) => c.id === item.id);
            existingItem.quantity++;
            existingItem.total_price = existingItem.price * existingItem.quantity;
            if (typeof window !== 'undefined') {
                localStorage.setItem("cartData", JSON.stringify(state.cart));
            }

        },
        decQuantity(state, action) {
            const item = action.payload;
            let existingItem = state.cart.find((c) => c.id === item.id);
            existingItem.quantity--;
            existingItem.total_price = existingItem.price * existingItem.quantity;
            if (typeof window !== 'undefined') {
                localStorage.setItem("cartData", JSON.stringify(state.cart));
            }
        },
        updateCart(state, action) {
            const { cartProduct, topping } = action.payload;
            let existingItem = state.cart.find((c) => c.id === cartProduct.id);
            existingItem.toppings = existingItem.toppings.filter(t => t._id !== topping._id);
            if (typeof window !== 'undefined') {
                localStorage.setItem("cartData", JSON.stringify(state.cart));
            }
        }
    },
});

// export const cartActions = cartSlice.actions;
// export default cartSlice;
export const {remove,addItem,incrementQuantity,decrementQuantity,clearCart,updateInsurance } =cartSlice.actions
export default cartSlice.reducer;
