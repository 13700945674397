import { createSlice } from "@reduxjs/toolkit";
const productSlice = createSlice({
    name: "product",
    initialState: {
        allProducts: [],
    },
    reducers: {
        setAllProducts(state, action) {
            state.allProducts = action.payload;
        },
    },
});

export const productActions = productSlice.actions;
export default productSlice;
