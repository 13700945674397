import Head from "next/head";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AuthConsumer, AuthProvider } from "src/contexts/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNProgress } from "src/hooks/use-nprogress";
import { createTheme } from "src/theme";
import { createEmotionCache } from "src/utils/create-emotion-cache";

import "simplebar-react/dist/simplebar.min.css";
import "public/assets/css/style.min.css";
import "public/assets/css/custom.css";
import store from "store";
import { Provider } from "react-redux";
import { useEffect } from "react";

const clientSideEmotionCache = createEmotionCache();

const SplashScreen = () => null;

const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  useNProgress();

  const getLayout = Component.getLayout ?? ((page) => page);

  const theme = createTheme();
  useEffect(() => {
    // Add event listener to hide scrollbar on scroll
    const handleScroll = () => {
      document.body.style.overflow = "hidden";
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Provider store={store}>
        <Head>
          <title>Agricare Global</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <meta name="viewport" content="user-scalable=no" />

          <meta
            content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
            name="viewport"
          />
        </Head>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ToastContainer autoClose={2000} style={{ padding: "16px" }} />

              <AuthConsumer>
                {(auth) =>
                  auth.isLoading ? <SplashScreen /> : getLayout(<Component {...pageProps} />)
                }
              </AuthConsumer>
            </ThemeProvider>
          </AuthProvider>
        </LocalizationProvider>
      </Provider>
    </CacheProvider>
  );
};

export default App;
