import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth-slice";
import cartSlice from "./cart/cart-slice";
import categorySlice from "./category/category-slice";
import productSlice from "./product/product-slice";
import toppingSlice from "./topping/topping-slice";
import vendorSlice from "./vendor/vendor-slice";


const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        // category: categorySlice.reducer,
        // product: productSlice.reducer,
        // topping: toppingSlice.reducer,
          cart: cartSlice,
        // vendor: vendorSlice.reducer,
    }
});
export default store;