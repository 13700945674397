import { createSlice } from "@reduxjs/toolkit";
const toppingSlice = createSlice({
    name: "topping",
    initialState: {
        allToppings: [],
        allCategoryWiseToppings: [],
    },
    reducers: {
        setAllToppings(state, action) {
            state.allToppings = action.payload;
        },
        setAllCategoryWiseToppings(state, action) {
            state.allCategoryWiseToppings = action.payload;
        }
    },
});

export const toppingActions = toppingSlice.actions;
export default toppingSlice;
